import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import Home from "../pages/Home"
import Package from '../pages/Package';
import MainWrapper from '../component/MainWrapper';
import About from '../pages/About';
import PrivacyPolicy from '../pages/privacyPolicy';
import Contact from '../pages/Contact';
import CategoryList from '../pages/CategoryList'
import ProjectList from '../pages/ProjectList';
import MediaList from '../pages/MediaList';
import Login from '../component/Login';
import LandingPage from '../pages/LandingPage';
import ThankYou from '../pages/ThankYou';
import FormPage from '../pages/FormPage';
import PlayerPage from '../pages/PlayerPage';
import HavsogaHomepage1 from '../component/HavsogaHomepage1';
import HavsogaHomepage2 from '../component/HavsogaHomepage2';
import HavsogaRegistreradPage from '../component/HavsogaRegistreradPage';
import HavsogaPage2 from '../component/HavsogaPage2';
import Portfolio from '../pages/Portfolio';
import Studio from "../pages/Studio";
import Sport from "../pages/Sport"
import RealEstate from '../pages/RealEstate';
import Individual from "../pages/Individual"
import HomeO from '../pages/Home-old';
import ScrollToTop from '../pages/ScrollToTop';
import HavsogaAnalitics from '../pages/HavsogaAnalitics';
import CaseStudyAnalitics from '../pages/CaseStudyAnalitics';
import ContactAnalitics from '../pages/ContactAnalitics';




const Routing = () => {
    return (
        <Router>
            <ScrollToTop />
            <Routes >
                <Route element={<MainWrapper />}>
                    <Route path="/" element={<Home />} />
                    <Route path="/home-old" element={<HomeO />} />
                    <Route path='/om-oss' element={<About />} />
                    <Route path="/package" element={<Package />} />
                    <Route path="/kontakt" element={<Contact />} />
                    <Route path="/admin" element={<Login />} />
                    <Route path="/admin/list" element={<CategoryList />} />
                    <Route path="/admin/projects/:id" element={<ProjectList />} />
                    <Route path="/admin/media/:categoty_id/:id" element={<MediaList />} />
                    <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                    <Route path="/thank-you" element={<ThankYou />} />
                    <Route path="/havsoga" element={<HavsogaHomepage1 />} />
                    {/* <Route path="/havsoga-old" element={<HavsogaHomepage2 />} /> */}
                    <Route path="/havsoga-registrerad" element={<HavsogaRegistreradPage />} />
                    <Route path="/havsoga-player" element={<HavsogaPage2 />} />
                    <Route path="/admin/havsoga_list" element={<HavsogaAnalitics />} />
                    <Route path="/admin/casestudy-list" element={<CaseStudyAnalitics />} />
                    <Route path="/admin/contact-list" element={<ContactAnalitics />} />
                    <Route path="/portfolio" element={<Portfolio />} />
                    <Route path="/studio" element={<Studio />} />
                    <Route path="/sport" element={<Sport />} />
                    <Route path="/real-estate" element={<RealEstate />} />
                    <Route path="/individual/:categoty_id/:id" element={<Individual />} />
                    <Route path="/socialamedier" element={<LandingPage />} />
                </Route>
                <Route path='/casestudy' element={<FormPage />} />
                <Route path='/player-page' element={<PlayerPage />} />

            </Routes>
        </Router>
    )
}

export default Routing;

