import { useState, useEffect } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import FAQ from "../component/FAQ";
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { contactUs } from '../store/api/course.api'
import { toast } from 'react-toastify';
import { useNavigate } from "react-router-dom";

const Contact = () => {

    const [value, setValue] = useState({ email: "" });
    const dispatch = useDispatch();
    const navigate = useNavigate();
    let require = []
    const onChange = (e) => {
        setValue({
            ...value,
            [e.target.name]: e.target.value,
        });
    };
    useEffect(() => {
        // Scroll the HTML and body elements to the top
        document.documentElement.scrollTop = 0;
        document.body.scrollTop = 0;
    }, []);
    const oncheckboxchange = (e) => {
        if (e.target.checked && !require.includes(e.target.value)) {
            require.push(e.target.value)
        } else {
            let index = require.indexOf(e.target.value); // 1
            console.log(index)
            require.splice(index, 1)

        }
        console.log(require)
    }
    const onAdd = () => {
        let data = {
            email: value.email,
            phone: value?.phone,
            message: value?.message,
            name: value?.name,
            business_name: value?.business_name,
            require_service: require.toString(),
            from_studio:false
        }
        dispatch(contactUs(data))
            .unwrap()
            .then((res) => {
                console.log(res.status)
                if (res.status === 200) {
                    navigate('/thank-you');
                }

            })
            .catch(() => {

            });
    };
    const openCal = () => {
        window.open('https://cal.com/filmlabbet', '_blank')
    }
    return (
        <>
            <div className="pb-md-120 pb-11 mt-neg40">
                <Container>
                    {/* <Link to="/" className="btn btn-link btn-back p-0 mb-9 d-md-none" >
                    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="11" viewBox="0 0 17 11" fill="none">
                        <path d="M1.22504 5.45014L0.799295 5.0244L0.373554 5.45014L0.799295 5.87588L1.22504 5.45014ZM15.6752 6.05223C16.0077 6.05223 16.2773 5.78266 16.2773 5.45014C16.2773 5.11761 16.0077 4.84805 15.6752 4.84805V6.05223ZM5.61601 0.207681L0.799295 5.0244L1.65078 5.87588L6.46749 1.05916L5.61601 0.207681ZM0.799295 5.87588L5.61601 10.6926L6.46749 9.84111L1.65078 5.0244L0.799295 5.87588ZM1.22504 6.05223H15.6752V4.84805H1.22504V6.05223Z" fill="#DCE1DE"/>
                    </svg>
                </Link> */}
                    <div className="block font-18 fw-light mb-28 text-center mb-40">
                        <h1 className="font-56 font-secondary">Let's get to work</h1>
                        <p>Välj att boka möte eller bli kontaktad</p>
                    </div>
                    <div className="blockwrap maxw-575 mx-auto text-center px-md-4 px-3 py-md-9 py-5 mb-9">
                        <div className="font-18 fw-light text-center letter-xs block mb-5">
                            <h2 className="fs-24 letter-sm mobile-font18">Boka möte</h2>
                            <p className="m-0">Klicka på knappen nedan för att boka ett möte med oss</p>
                        </div>
                        <Button variant="primary" type="button" onClick={openCal}>
                            Boka möte
                        </Button>
                    </div>

                    <div className="maxw-745 mx-auto mb-9 px-8">
                        <div className="border-top"></div>
                    </div>

                    <div className="contact-blockwrap position-relative">
                        <div className="blockwrap py-md-40 py-32 px-md-30 px-20 h-100">
                            <div className="font-18 fw-light mb-32 text-center letter-xs block mb-30">
                                <h2 className="fs-24 letter-sm mobile-font18">Kontakta oss</h2>
                                <p>Vi hör alltid av oss inom 24 timmar.</p>
                            </div>
                            <Form className="d-flex flex-column gap-20 mb-9">
                                <Row className="g-lg-4 g-3">
                                    <Col lg={6}>
                                        <Form.Group controlId="inputName">
                                            <Form.Control type="text" placeholder="*Namn" name='name' value={value.name} onChange={onChange} />
                                        </Form.Group>
                                    </Col>
                                    <Col lg={6}>
                                        <Form.Group controlId="inputPost">
                                            <Form.Control type="email" placeholder="*E-post" name='email' value={value.email} onChange={onChange} />
                                        </Form.Group>
                                    </Col>
                                    <Col lg={6}>
                                        <Form.Group controlId="inputTel">
                                            <Form.Control type="tel" placeholder="*Telefon" name='phone' value={value.phone} onChange={onChange} />
                                        </Form.Group>
                                    </Col>
                                    <Col lg={6}>
                                        <Form.Group>
                                            <Form.Control type="text" placeholder="*Företag" name='business_name' value={value.business_name} onChange={onChange} />
                                        </Form.Group>
                                    </Col>
                                    <Col lg={12}>
                                        <Form.Group>
                                            <Form.Control
                                                className="contact-teaxtarea"
                                                as="textarea"
                                                placeholder="*Meddelande"
                                                name="message"
                                                value={value.message} onChange={onChange}
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>

                            </Form>
                            {/* <div className="font-18 fw-light mb-32 text-center letter-xs block mb-30">
                                <h2 className="fs-24 letter-sm mobile-font18 mb-0">Vilka tjänster vill du ha hjälp med?</h2>
                            </div>
                            <Form className="contact-check-wrap">
                                {['checkbox'].map((type) => (
                                    <div key={`ContactCheck-${type}`} className="mb-3">
                                        <Form.Check
                                            className="mb-lg-2 mb-1"
                                            label="Sociala medier - Innehållsstrategi & analys"
                                            name="group1"
                                            type={type}
                                            value={"Sociala medier - Innehållsstrategi & analys"}
                                            onChange={oncheckboxchange}
                                            id={`ContactCheck-${type}-1`}
                                        />
                                        <Form.Check
                                            className="mb-lg-2 mb-1"
                                            label="Sociala medier - Innehållsproduktion (content)"
                                            name="group2"
                                            value={"Sociala medier - Innehållsproduktion (content)"}
                                            onChange={oncheckboxchange}
                                            type={type}
                                            id={`ContactCheck-${type}-2`}
                                        />
                                        <Form.Check
                                            className="mb-lg-2 mb-1"
                                            label="Sociala medier - Management (publicering, bildtext och hashtags)"
                                            name="group3"
                                            value={"Sociala medier - Management (publicering, bildtext och hashtags)"}
                                            onChange={oncheckboxchange}
                                            type={type}
                                            id={`ContactCheck-${type}-3`}
                                        />
                                        <Form.Check
                                            className="mb-lg-2 mb-1"
                                            label="Sociala medier - Betald annonsering"
                                            name="group4"
                                            value={"Sociala medier - Betald annonsering"}
                                            onChange={oncheckboxchange}
                                            type={type}
                                            id={`ContactCheck-${type}-4`}
                                        />
                                        <Form.Check
                                            className="mb-lg-2 mb-1"
                                            label="Filmproduktion (företagsfilm, reklamfilm och liknande)"
                                            name="group5"
                                            value={"Filmproduktion (företagsfilm, reklamfilm och liknande)"}
                                            onChange={oncheckboxchange}
                                            type={type}
                                            id={`ContactCheck-${type}-5`}
                                        />
                                        <Form.Check
                                            className="mb-lg-2 mb-1"
                                            label="Fotoproduktion (företagsfoto, produktfoto och liknande)"
                                            name="group6"
                                            value={"Fotoproduktion (företagsfoto, produktfoto och liknande)"}
                                            onChange={oncheckboxchange}
                                            type={type}
                                            id={`ContactCheck-${type}-6`}
                                        />
                                        <Form.Check
                                            className="mb-lg-2 mb-1"
                                            label="Vet inte exakt... kan va allt/kan va inget"
                                            name="group7"
                                            value={"Vet inte exakt... kan va allt/kan va inget"}
                                            onChange={oncheckboxchange}
                                            type={type}
                                            id={`ContactCheck-${type}-7`}
                                        />
                                    </div>
                                ))}
                            </Form> */}
                            <div className="d-flex align-items-center justify-content-center mt-9">
                                <Button variant="primary" type="button"  onClick={onAdd}>
                                    Skicka
                                </Button>
                            </div>
                        </div>
                        {/* <Col lg={6}>
                            <div className="blockwrap py-md-40 py-32 px-md-30 px-20 h-100">
                                <div className="font-18 fw-light mb-32 text-center letter-xs block mb-30">
                                    <h2 className="fs-24 letter-sm mobile-font18">Vill du kontakta?</h2>
                                    <p>Vi svarar så fort vi kan.</p>
                                </div>
                                <div className="block contactbox-block">
                                    <div className="block fw-light font-18 py-20 contactblock-info">
                                        <h5 className="fw-bold mb-4px">Maila oss</h5>
                                        <a href="mailto:Marcus@filmlabbet.com" className="text-white text-decoration-none link-hover">Marcus@filmlabbet.com</a>
                                    </div>
                                    <div className="block fw-light font-18 py-20 contactblock-info">
                                        <h5 className="fw-bold mb-4px">Ring oss</h5>
                                        <a href="tel:+46768357519" className="text-white text-decoration-none link-hover">+46 768 35 75 19</a>
                                    </div>
                                    <div className="block fw-light font-18 py-20 contactblock-info">
                                        <h5 className="fw-bold mb-4px">Besök oss</h5>
                                        <a href="https://www.google.com/maps/place/Dumpergatan+3,+442+40+Kung%C3%A4lv,+Sweden/@57.8757673,11.9352723,17z/data=!3m1!4b1!4m6!3m5!1s0x464560e12a61e795:0xd5381b69d555a9dd!8m2!3d57.8757673!4d11.9378472!16s%2Fg%2F11csdr6hjt?entry=ttu" target="_blank" className="text-white text-decoration-none link-hover">Dumpergatan 3, Kungälv</a>

                                    </div>
                                </div>
                            </div>

                        </Col> */}
                    </div>
                </Container>
            </div>
            <FAQ />
        </>

    )
}

export default Contact;