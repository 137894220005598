import { Button, Col, Container, Image, Row } from "react-bootstrap";
import FAQ from "../component/FAQ";

const About = () => {
    return (
        <div className="">
            {/* <Container>
                <Button variant="link" className="btn-back p-0 mb-9 d-md-none" >
                        <svg xmlns="http://www.w3.org/2000/svg" width="17" height="11" viewBox="0 0 17 11" fill="none">
                            <path d="M1.22504 5.45014L0.799295 5.0244L0.373554 5.45014L0.799295 5.87588L1.22504 5.45014ZM15.6752 6.05223C16.0077 6.05223 16.2773 5.78266 16.2773 5.45014C16.2773 5.11761 16.0077 4.84805 15.6752 4.84805V6.05223ZM5.61601 0.207681L0.799295 5.0244L1.65078 5.87588L6.46749 1.05916L5.61601 0.207681ZM0.799295 5.87588L5.61601 10.6926L6.46749 9.84111L1.65078 5.0244L0.799295 5.87588ZM1.22504 6.05223H15.6752V4.84805H1.22504V6.05223Z" fill="#DCE1DE"/>
                        </svg>
                    </Button>
            </Container> */}
            <section className="pb-md-15 pb-11 mt-neg40">
                <Container>
                    <Row className="gx-14px">
                        <Col md={5}>
                            <div className="font-18 fw-light d-flex flex-column gap-3 mb-md-0 mb-9 block">
                                <h1 className="font-56 font-secondary mb-0 maxw-387">Hej, det är vi som är Filmlabbet👋</h1>
                                <span className="font-24 fw-light">Vår passion är att skapa högkvalitativt innehåll – oavsett om det gäller lite längre företagsfilmer, till marknadsföringskampanjer eller kort, kreativt material till era sociala medier.</span>
                                <div className="opacity-90">

                                    <p className="letter-xs mb-30">Varje företag är unikt och vi gör därför allt vi kan för att lyssna in vad just ni behöver. Varje projekt skräddarsys för att återspegla er företagsidentitet och era mål, det gäller såväl engångsproduktioner som kontinuerliga produktioner till sociala medier.</p>
                                    <p className="letter-xs mb-30"> Vi är lyhörda, kreativa och framför allt, flexibla. Vi finns med under hela processen, från planering till inspelning och redigering. Ni bestämmer själv hur delaktiga ni vill vara - men vi har märkt att ett nära samarbete brukar ge de allra bästa resultaten.</p>
                                    <p className="letter-xs mb-md-30 mb-0">Tveka inte att höra av er till oss så berättar vi mer om hur vi kan hjälpa er! </p>
                                </div>
                            </div>
                        </Col>
                        <Col md={7}>
                            <Row className="g-2">
                                <Col xs={12} className="order-md-0 order-2">
                                    <div>
                                        <Image src="./assets/images/image15.png" alt="Image1" className="rounded-4" />
                                    </div>
                                </Col>
                                <Col xs={6} className="order-md-1 order-0">
                                    <div>
                                        <Image src="./assets/images/image12.png" alt="Image1" className="rounded-4" />
                                    </div>
                                </Col>
                                <Col xs={6} className="order-md-2 order-1">
                                    <div>
                                        <Image src="./assets/images/image13.png" alt="Image1" className="rounded-4" />
                                    </div>
                                </Col>

                            </Row>
                        </Col>
                    </Row>
                </Container>
            </section>
            <FAQ />
        </div>
    )
}

export default About;