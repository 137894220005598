import { Button, Container, Image, Row, Col } from "react-bootstrap";
import { useNavigate, Link } from 'react-router-dom';

const Home = () => {

    const navigate = useNavigate();

    const OpenContact = () => {
        navigate('/kontakt');
    }
    const OpenPortfolio = () => {
        navigate('/portfolio');
    }
   
    return (
        <section className="banner-wrapper">
            <Container>
                <div className="text-center d-flex flex-column justify-content-center">
                    <div className="maxw-918 mx-auto mb-5">
                        <h1 className="font-56 text-white font-secondary mb-2 letter-space-1 logo-text">Filmlabbet <span className="logo-dot"></span></h1>
                        <span className="letter-sm fw-light font-24 maxw-776 mx-auto d-block">Produktionsbolag från väst.</span>
                    </div>
                    <div className="gap-3 d-flex align-items-center justify-content-center">
                        <Button onClick={OpenPortfolio} variant="primary" >Portfolio</Button>
                        <Button onClick={OpenContact} variant="white">Kontakt</Button>
                    </div>
                </div>
            </Container>
        </section>
    )
}

export default Home;