import { useEffect,useState } from "react";
import { Button, Container, Image, Row, Col,Dropdown } from "react-bootstrap";
import { useNavigate, Link } from 'react-router-dom';
import { getProjectList } from '../store/api/course.api';
import { useDispatch } from 'react-redux';
import ComonDropDown from "../component/DropDownComon";

const Portfolio = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [allCources, setAllCources] = useState();
    localStorage.setItem("catName","Narrative");
    const OpenPage = (id, name) => {
        console.log(name)
        if(name === 'Havsöga') {
            navigate('/havsoga');
        } else {
            navigate('/individual/portfolio/'+id);
        }
        
    }
   
    useEffect(() => {
        refresh();
    }, []);
    const refresh = () => {
        let data = {
          'id': 9,
        }
          dispatch(getProjectList(data)).then((course) => {
              setAllCources(course.payload?.data);
          });
      }
    return (
        <div className="pt-md-0 pt-70">
            <section className="studio banner-wrapper pb-md-100 pb-80">
                <Container>
                    <div className="text-center d-flex flex-column justify-content-center">
                        <div className="maxw-918 mx-auto">
                            <h1 className="font-56 text-white font-secondary mb-2 letter-space-1">Portfolio</h1>
                            <span className="letter-sm fw-light font-24  mx-auto d-block" style={{'max-width' : '713px'}}>Se våra tidigare case 🔥</span>
                               <ComonDropDown/>
                        </div>
                    </div>
                </Container>
            </section>
            <section className="studio-grids pb-md-150 pb-100">
                <Container fluid>
                    <Row className="g-10px">
                    {

                allCources && allCources.length > 0 && allCources?.map((item, index) => (
                        <Col xxl={3} sm={4} key={item.id} onClick={()=>OpenPage(item.id,item?.title)}>
                            <div className="grid-img-box">
                                <div className="grid-img-boxwrap">
                                    <Image src={item?.image}></Image>
                                </div>
                                <div className="grid-img-details text-white text-center">
                                    <h2 className="mb-0">{item?.title}</h2>
                                    <p className=" mb-0">{item?.sub_title}</p>
                                </div>
                            </div>
                        </Col>
                         ))
                        }
                    </Row>
                </Container>
            </section>

        </div>
    )
}

export default Portfolio;