import { Dropdown } from "react-bootstrap";
import { useNavigate, Link } from 'react-router-dom';


const ComonDropDown = () => {

    const navigate = useNavigate();
    const gotoPage = (pagaName) => {
        navigate('/' + pagaName);
    }
    return (
        <>
            <Dropdown className="portfolio-dropdown">
                <Dropdown.Toggle variant="success" id="dropdown-basic">
                    {localStorage.getItem("catName")}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                    <Dropdown.Item onClick={() => gotoPage('portfolio')}>Narrative</Dropdown.Item>
                    <Dropdown.Item onClick={() => gotoPage('sport')}>Sport</Dropdown.Item>
                    <Dropdown.Item onClick={() => gotoPage('real-estate')}>Fastighet</Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>


        </>
    )
}

export default ComonDropDown;